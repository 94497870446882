.selectButton {
    /* display: none; */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #f2f2f2;
    color: rgb(0, 0, 0);
    font-size: 16px;
    padding: 10px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.70));
    font-weight: bold;
}

/* Show button on hover */


.slideshow-row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: 640px;
    /* Adjust the height as needed */
}

.sliderimage {
    height: 300px;
    width: 250px;
    /* object-fit: cover; */
    /* Equivalent to object-cover */
    border-radius: 0.5rem;
    /* Equivalent to rounded-lg */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06);
    /* Equivalent to shadow-xl */
    margin: 0.5rem;
    /* Equivalent to mx-2 */
}

@media (max-width: 801px) {
    .slideshow-row {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        height: 330px;
        /* Adjust the height as needed */
    }

    .sliderimage {
        height: 150px;
        width: 100px;
    }


}

@media (min-width: 801px) {
    .sliderp {
        color: #864BD8;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 2.72px;
        margin-left: 120px;
    }

    .sliderp2 {
        color: #292929;
        font-family: Inter;
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: 50px;
        /* 100% */
        letter-spacing: -1px;
        margin-left: 120px;
    }


}


@media (max-width: 801px) {
    .sliderp {
        color: #864BD8;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 2.72px;
        text-align: center;
    }

    .sliderp2 {
        color: #292929;
        font-family: Inter;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 50px;
        /* 100% */
        letter-spacing: -1px;
        text-align: center;
    }


}



/* Templet.css */

/* Add these styles to move the arrows closer to the Premium Templets text */
.prevArrow,
.nextArrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    cursor: pointer;
}

.prevArrow {
    left: 10px;

}

.nextArrow {
    right: 10px;

}

.boxshadow {
    filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.70));
    background-color: #ffffff;
    border-radius: 10px;
}

/* YourModalStyles.css */

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
        transform: translateY(0);
    }

    to {
        opacity: 0;
        transform: translateY(-20px);
    }
}

.custom-modal {
    animation-duration: 0.5s;
    /* Adjust the animation duration */
    animation-timing-function: ease-in-out;
    /* Adjust the animation timing function */
}

.custom-modal.fade-in {
    animation-name: fadeIn;
}

.custom-modal.fade-out {
    animation-name: fadeOut;
}

.app {
    display: flex;
    justify-content: center;
    align-items: center;
}

.open-modal-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-container {
    background: #fff;
    padding: 5px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: 85%;
    text-align: center;
}

.modal-content h1 {
    margin-bottom: 10px;
}

.modal-content p {
    margin-bottom: 20px;
    color: #666;
}

.biodata-preview {
    display: flex;
    justify-content: center;
}

.biodata-preview img {
    width: 100%;
    max-width: 300px;
    border: 2px solid #ddd;
    border-radius: 8px;
}

.template-options {
    display: flex;
    justify-content: space-around;
    padding: 15px;
    margin: 20px 0px;
    background: #f1f0f0;
    border-radius: 20px;
}

.template-options img {
    width: 60px;
    height: 80px;
    border: 2px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    transition: border-color 0.3s;
}

.template-options img:hover {
    border-color: #000;
}

.button-container {
    display: flex;
    justify-content: center;
    gap: 30px;
}

.cancel-button,
.download-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.cancel-button {
    background-color: #000000;
    color: #ffffff;
}

.download-button {
    background-color: #ff6600;
    color: #fff;
}