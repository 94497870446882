.app {
    display: flex;
    justify-content: center;
    align-items: center;
}

.open-modal-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-container {
    background: #fff;
    padding: 5px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: 85%;
    text-align: center;
}

.modal-content h1 {
    margin-bottom: 10px;
}

.modal-content p {
    margin-bottom: 20px;
    color: #666;
}
.biodata-preview{
    display: flex;
    justify-content: center;
}

.biodata-preview img {
    width: 100%;
    max-width: 300px;
    border: 2px solid #ddd;
    border-radius: 8px;
}

.template-options {
    display: flex;
    justify-content: space-around;
    padding: 15px;
    margin: 20px 0px;
    background: #f1f0f0;
    border-radius: 20px;
}

.template-options img {
    width: 60px;
    height: 80px;
    border: 2px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    transition: border-color 0.3s;
}

.template-options img:hover {
    border-color: #000;
}

.button-container {
    display: flex;
    justify-content: center;
    gap: 30px;
}

.cancel-button,
.download-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.cancel-button {
    background-color: #000000;
    color: #ffffff;
}

.download-button {
    background-color: #ff6600;
    color: #fff;
}