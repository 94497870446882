.form-header {
    font-weight: 600;
    text-align: center;
    color: #05173B;
    margin: 0px;
}

.form-headers {
    font-size: 23px;
    font-weight: 600;
    line-height: 52.5px;
    text-align: center;
    color: #05173B;
    margin: 0px;
    text-decoration: underline;
    margin-top: 100px;
}

.form-text {
    font-size: 20px;
    font-weight: 400;
    line-height: 54px;
    text-align: center;
    margin: 0px;
}

.image-setup {
    cursor: pointer;
    height: 200px;
    width: 180px;
    /* background-color: #F9F9F9; */
    border-radius: 25px;
    border: 2px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@media (max-width: 575px) {

    .form-text {
        font-size: 15px;
        font-weight: 400;
        line-height: 54px;
        text-align: center;
        margin: 0px;
    }
}

@media (max-width: 575px) {

    .form-container {
        width: 90% !important;
    }
}

.icon-background {
    height: 60px;
    width: 60px;
    border-radius: 80px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-background img {
    height: 35px;
}

.image-detail {
    letter-spacing: 1px;
    text-align: center;
}

.imagebackground {
    background-color: #ffffff;
    height: 220px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    margin-top: 30px;
}

.from-main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.form-container {
    width: 80%;
    margin: 0 auto;
    background-color: #ffffff;
    border: 1px solid #E0E0E0;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    margin-top: 50px;
}

.form-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.icon-container img {
    width: 30px;
    height: 30px;
}

.form-header h2 {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
}

.editable-header {
    cursor: pointer;
}

.header-input {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
}

.edit-icon {
    cursor: pointer;
}

.collapse-icon {
    font-size: 20px;
    cursor: pointer;
    color: #ec1919;
}

.form-body {
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* background-color: #F9F9F9; */
    border: 1px solid #E0E0E0;
    border-radius: 20px;
}

.form-field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-radius: 10px;
    flex-direction: column;
    gap: 10px;
}

.field-label {
    display: flex;
    /* align-items: center; */
    gap: 5px;
    width: 100%;
}

.label-icon img {
    width: 20px;
    height: 20px;
}

.input-container {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
}

.field-input {
    width: 100%;
    border: none;
    outline: none;
    background: none;
    font-size: 16px;
    padding: 5px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding-right: 30px;
    height: 40px;
    /* Add space for delete icon */
}

.field-input.filled {
    background-color: #F0F0F0;
}

.delete-icon {
    position: absolute;
    right: 5px;
    cursor: pointer;
}

.delete-icon img {
    width: 15px;
    height: 17px;
}

.form-footer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.add-new-button {
    background-color: #FF5722;
    color: #ffffff;
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.add-new-button span {
    font-size: 20px;
    margin-left: 5px;
}

.form-footer {
    margin-top: 60px;
    margin-bottom: 60px;
}