/* @import url('https://fonts.googleapis.com/css2?family=Edu+TAS+Beginner:wght@700&family=Fredericka+the+Great&family=Playball&display=swap'); */

.name {
    color: #000;
    font-family: Leckerli One;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.app-container {
    position: relative;
    height: 100vh;
    /* Set the container height to the full viewport height */
}

.loading-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* styles.css */

/* Media Query for window width <= 900px */
@media (max-width: 900px) {
    .image-1 {
        position: absolute;
        top: 80px;
        height: 120px;
    }

    .image-2 {
        position: absolute;
        top: 0;
    }

    .image-3 {
        position: absolute;
        top: 3px;
        right: 60px;
    }

    .image-4 {
        position: absolute;
        top: 55px;
        right: 0;
        height: 120px;
    }
}

/* Media Query for window width > 900px */
@media (min-width: 901px) {
    .image-1 {
        position: absolute;
        top: 150px;
    }

    .image-2 {
        position: absolute;
        top: 50px;
    }

    .image-3 {
        position: absolute;
        top: 30px;
        right: 390px;
    }

    .image-4 {
        position: absolute;
        top: 50px;
        right: 150px;
    }
}

/* styles.css */

/* Common styles for both window widths */
.heading-container {
    color: #292929;
    text-align: center;
    font-family: Montserrat;
    font-weight: 700;
}

/* Media Query for window width <= 800px */
@media (max-width: 800px) {
    .heading-container {
        font-size: 30px;
        line-height: 40px;
        letter-spacing: -2px;
    }

    .heading-container p:nth-child(2) {
        display: flex;
        text-align: center;
        justify-content: center;
    }

    .heading-container p:nth-child(2) p {
        margin-right: -30px;
    }

    .heading-container img {
        width: 106px;
        height: 50px;
        flex-shrink: 0;
    }

    .heading-container p:nth-child(2) p:last-child {
        margin-left: -30px;
    }


}

/* Media Query for window width > 800px */
@media (min-width: 801px) {
    .heading-container {
        font-size: 70px;
        line-height: 80px;
        letter-spacing: -2px;
    }

    .heading-container p:nth-child(2) img {
        height: 106px;
    }


}

.howitwork {
    color: #0D8772;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 2.72px;
}

.workchild {
    color: #292929;
    text-align: center;
    font-family: Montserrat;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    /* 120% */
    letter-spacing: -1px;
    margin-top: 50px;
}

.detailstape {
    width: 300px;
    height: 330px;
    flex-shrink: 0;
    border-radius: 16px;
    border: 2px solid #F2F2F2;
    background: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 10px;
}

.detailboxes {
    width: 300px;
    height: 330px;
    flex-shrink: 0;
    border-radius: 16px;
    background: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 10px;
}

.detail {
    color: #292929;
    text-align: center;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.583px;
    margin-top: 30px;
}

.otherdetail {
    color: #6F6F75;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 162.5% */
    width: 255px;
}

.freetemplet {
    color: #F57343;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 2.72px;
    text-align: center;
}

.detailbox {
    width: 250px;
    height: 228px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #EDEDED;
    background: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
}

.detaikboxp {
    color: #919098;
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 122.222% */
    letter-spacing: -0.4px;
}

.detailicon {
    width: 74px;
    height: 74px;
    flex-shrink: 0;
    background-color: rgb(228 247 241);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.detailicon1 {
    width: 74px;
    height: 74px;
    flex-shrink: 0;
    background-color: rgb(251 227 215);
    ;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.detailicon2 {
    width: 74px;
    height: 74px;
    flex-shrink: 0;
    background-color: rgb(247 239 221);
    ;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.detailicon3 {
    width: 74px;
    height: 74px;
    flex-shrink: 0;
    background-color: rgb(227 215 245);
    ;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.testmotion {
    color: #0D8772;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 3.2px;
}

.costomer {
    color: #292929;
    text-align: center;
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
    /* 120% */
    letter-spacing: -1px;
    margin-top: 10px;
}

.custoimg {
    width: 250px;
    height: 300px;
    flex-shrink: 0;
}

.cusname {
    color: #292929;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    /* 100% */
    letter-spacing: -0.4px;
}

.cusnames {
    color: #83828A;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    /* 100% */
    letter-spacing: -0.4px;
    padding-bottom: 30px;
}

.cusrew {
    color: #5B5A62;
    font-family: Inter;
    font-size: 20px;
    font-style: italic;
    font-weight: 500;
    line-height: 36px;
    /* 180% */
    letter-spacing: -0.4px;
}

.star {
    width: 99px;
    height: 18.358px;
    flex-shrink: 0;
}



.bgp {
    color: #BC95F2;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 3.2px;
}

.bgp2 {
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
    /* 120% */
    letter-spacing: -1px;
}

.rewinput {
    border-radius: 37.5px;
    background: #FFF;
    box-shadow: 0px 40px 100px -40px #120500;
    width: 502px;
    height: 65px;
    flex-shrink: 0;
    margin-top: 30px;
    border: none;
}



/* Add this to your App.css file or another CSS file */