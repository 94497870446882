/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  background: #fff;
  color: #666666;
  font-family: "Open Sans", sans-serif;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

a {
  color: #000000;
  text-decoration: none;
  transition: 0.5s;
}

a:hover,
a:active,
a:focus {
  color: #000000;
  outline: none;
  text-decoration: none;
}

p {
  padding: 0;
  margin: 0 0 30px 0;
  font-family: "Montserrat", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  margin: 0 0 20px 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}

.container {
  max-width: 95%;

  padding: 0px 12px;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  height: 80px;
  transition: all 0.5s;
  z-index: 997;
  background: white;
}

#header.header-transparent {
  background: white;
}

#header.header-scrolled {
  background: white;
  /* height: 110px; */
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.5s;
}

#header #logo h1 {
  font-size: 36px;
  padding: 0;
  margin: 0;
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  letter-spacing: 3px;
  text-transform: uppercase;
}

#header #logo h1 a,
#header #logo h1 a:hover {
  color: #fff;
}

/*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar>ul>li {
  white-space: nowrap;
  padding: 10px 0 10px 30px;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Montserrat", sans-serif;
  color: rgba(0, 0, 0, 0.8);
  font-size: 19px;
  font-weight: 600;
  padding: 0 2px;
  white-space: nowrap;
  transition: 0.3s;
  letter-spacing: 0.4px;
  position: relative;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar>ul>li>a:before {
  content: "";
  position: absolute;
  width: 70%;
  height: 3px;
  bottom: -8px;
  left: 8px;
  background-color: #fe5300;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
  box-shadow: 0px 4px 4px 0px rgb(0 0 0 / 25%);
  border-radius: 150px;
}

.navbar a:hover:before,
.navbar li:hover>a:before,
.navbar .active:before {
  visibility: visible;
  transform: scaleX(1);
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
  color: #fe5300;
}

/**
  * Mobile Navigation 
  */
.mobile-nav-toggle {
  color: #ff4500;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
  padding-right: 15px;
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(26, 26, 26, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 30px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile>ul>li {
  padding: 0;
}

.navbar-mobile a:hover:before,
.navbar-mobile li:hover>a:before,
.navbar-mobile .active:before {
  visibility: hidden;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #333333;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
  color: #fe5300;
}

.login_btn {
  padding: 14px 29px;
  border-radius: 35px;
  color: white;
  border: 0px;
  margin: 10px 0 10px 30px;
  background-color: black;
  line-height: normal;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  /* background: #f9f9f9; */
  background-size: cover;
  position: relative;
  padding: 0px 0;
}

.hero_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 130px 0 0 0;
}

.hero_text {
  width: 50%;
}

.hero_img {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}

.hero_title {
  font-size: 72px;
  line-height: 80px;
  font-weight: bold;
  color: #292929;
}

.hero_title_primary {
  color: #012367;
}

.hero_title_primary_inner {
  font-family: "Oleo Script Swash Caps", system-ui;
  color: #fe5300;
}

.hero_inner_text {
  color: #5b5a62;
  font-size: 21px;
  line-height: 28px;
}

.hero_inner_img {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
  width: 100%;
}

.hero_button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 23px 100px 23px 34px;
  background-color: #ff4500;
  color: white;
  font-size: 21px;
  font-weight: bold;
  border: none;
  border-radius: 26px;
  cursor: pointer;
  text-decoration: none;
  box-shadow: 0px 8px 49px 0px rgba(254, 83, 0, 33%);
  transition: background-color 0.3s ease;
}

.hero_button:hover {
  background-color: #ffffff;
  color: #fe5300;
  border: 1px solid #fe5300;
  transition: all 0.3s ease-in-out;
}

.hero_button .icon {
  position: absolute;
  right: 9%;
  margin-left: 10px;
  font-size: 18px;
  display: flex;
  align-items: center;
}

i.fa-solid.fa-chevron-right.first {
  font-size: 15px;
}

i.fa-solid.fa-chevron-right.last {
  font-size: 21px;
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

/* **************************************** trending_temp **************************************** */
.trending_temp {
  padding: 70px 0px;
  overflow: hidden;
  text-align: center;
}

.trending {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.trending_title {
  font-size: 35px;
  color: #05173b;
  line-height: normal;
  font-weight: 600;
}

.trending_sub_title {
  font-size: 20px;
  color: #5b5a62;
  line-height: 28px;
}

.kafelki_container {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.kafelki_1,
.kafelki_2,
.kafelki_3,
.kafelki_4,
.kafelki_5,
.kafelki_6 {
  width: 100%;
  max-width: 300px;
  padding: 10px;
  text-align: center;
}

.kafelki_item_1 {
  position: relative;
}

.kafelki_item_1 .box {
  width: 100%;
  padding: 50px 10px;
  background: #fff;
  color: #000;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.kafelki_item_1 .box:hover {
  transform: translateY(-10px);
}

.tech-savvy {
  background-color: #f2f2f2;
}

.gradient-delight {
  background-color: #e6e6e6;
}

.nature-inspired {
  background-color: #d9d9d9;
}

.futuristic-vibes {
  background-color: #cccccc;
}

.bold-typography {
  background-color: #bfbfbf;
}

.vintage-charm {
  background-color: #b3b3b3;
}

/* **************************************** how_work **************************************** */
.how_work {
  /* padding: 70px 0px; */
  overflow: hidden;
  text-align: center;
}

.how_title {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.how_it_title {
  font-size: 35px;
  color: #05173b;
  line-height: normal;
  font-weight: 600;
}

.how_it_inner_text {
  font-size: 20px;
  color: #5b5a62;
  line-height: 28px;
  margin-top: 10px;
}

/* **************************************** premium_temp **************************************** */
.premium_temp {
  padding: 70px 0px;
  overflow: hidden;
  text-align: center;
}

.premium_temp h2 {
  font-size: 35px;
  color: #05173b;
  line-height: normal;
  font-weight: 600;
}

.premium_temp p {
  font-size: 20px;
  color: #5b5a62;
  line-height: 28px;
  margin-top: 10px;
}

#Templet {
  /* background: #efeef3; */
  /* padding: 50px 0; */
  /* margin-top: 100px; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* **************************************** choose_by **************************************** */
.choose_by {
  padding: 70px 0px;
  overflow: hidden;
  text-align: center;
}

.choose_by_title {
  font-size: 35px;
  color: #05173b;
  line-height: normal;
  font-weight: 600;
}

.choose_by_inner_text {
  font-size: 20px;
  color: #5b5a62;
  line-height: 28px;
  margin-top: 10px;
}

.category_template {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.bu_template {
  width: 100%;
  max-width: 300px;
  padding: 10px;
  text-align: center;
}

.bu_title {
  font-size: 28px;
  color: #05173b;
  line-height: normal;
  font-weight: 600;
  margin-top: 10px;
}

.bu_inner_text {
  font-size: 18px;
  color: #5b5a62;
  line-height: 28px;
  margin-top: 10px;
}

/* **************************************** blog_area **************************************** */
.blog_area {
  /* padding: 70px 0px; */
  overflow: hidden;
  text-align: center;
}

.blog_title {
  font-size: 35px;
  color: #05173b;
  line-height: normal;
  font-weight: 600;
}

.blog_inner_title {
  font-size: 28px;
  color: #05173b;
  line-height: normal;
  font-weight: 600;
  margin-top: 10px;
}

.blog_text {
  font-size: 20px;
  color: #5b5a62;
  line-height: 28px;
  margin-top: 10px;
}

.blog_border {
  border: 1px solid #cccccc;
  padding: 20px;
  margin-top: 20px;
}

.blog_background_img {
  /* background-image: url("assets/images/blog_background.png"); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blogsubscribe {
  /* background: rgba(255, 255, 255, 0.9); */
  padding: 20px;
  text-align: center;
}

.blog_subscribe_text {
  color: #05173b;
}

.blog_subscribe_text h4 {
  font-size: 28px;
  font-weight: 600;
}

.blog_subscribe_text h3 {
  font-size: 35px;
  font-weight: 600;
}

.blog_subscribe_text p {
  font-size: 18px;
  color: #5b5a62;
  line-height: 28px;
}

.blog_input {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.searchbox-wrap {
  display: flex;
}

.searchbox-wrap input {
  padding: 14px 20px;
  font-size: 16px;
  border: 1px solid #cccccc;
  border-radius: 5px 0 0 5px;
}

.searchbox-wrap button {
  padding: 14px 20px;
  font-size: 16px;
  border: 1px solid #cccccc;
  background-color: #ff4500;
  color: white;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
}

/* Media Queries */

@media (max-width: 1200px) {
  .hero_wrap {
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
    /* padding: 50px 0; */
    /* margin-top: 70px; */
  }

  .hero_text,
  .hero_img {
    width: 100%;
  }

  .hero_title {
    font-size: 48px;
    line-height: 56px;
  }

  .hero_inner_text {
    font-size: 18px;
    line-height: 24px;
  }

  .hero_button {
    padding: 20px 60px;
  }

  .kafelki_container {
    flex-direction: column;
  }

  .kafelki_1,
  .kafelki_2,
  .kafelki_3,
  .kafelki_4,
  .kafelki_5,
  .kafelki_6 {
    width: 100%;
    max-width: 100%;
  }

  .choose_by .category_template {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .hero_title {
    font-size: 36px;
    line-height: 44px;
  }

  .hero_inner_text {
    font-size: 16px;
    line-height: 20px;
  }

  .hero_button {
    padding: 18px 50px;
    font-size: 18px;
  }

  .blog_subscribe_text h4 {
    font-size: 24px;
  }

  .blog_subscribe_text h3 {
    font-size: 28px;
  }

  .blog_subscribe_text p {
    font-size: 16px;
  }

  .searchbox-wrap input {
    width: 60%;
  }

  .searchbox-wrap button {
    /* width: 40%; */
  }
}

@media (max-width: 480px) {
  .hero_title {
    font-size: 28px;
    line-height: 34px;
  }

  .hero_inner_text {
    font-size: 14px;
    line-height: 18px;
  }

  .hero_button {
    /* padding: 16px 40px; */
    font-size: 16px;
  }

  .blog_subscribe_text h4 {
    font-size: 20px;
  }

  .blog_subscribe_text h3 {
    font-size: 24px;
  }

  .blog_subscribe_text p {
    font-size: 14px;
  }

  .searchbox-wrap input {
    width: 70%;
  }

  .searchbox-wrap button {
    /* width: 30%; */
  }
}

.site-footer {
  background-color: #f8f9fa;
  padding: 20px 0;
  border-top: 1px solid #dee2e6;
}

.container {
  max-width: 95%;
  margin: 0 auto;
  padding: 0 15px;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.footer-section {
  margin-bottom: 20px;
}

.section-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.about p {
  max-width: 500px;
  line-height: 1.6;
  color: #6c757d;
}

.links-flex {
  display: flex;
  gap: 40px;
}

.link-list,
.contact-list {
  list-style: none;
  padding: 0;
}

.link-list li,
.contact-list li {
  margin-bottom: 10px;
}

.link-list a,
.contact-item {
  text-decoration: none;
  color: #007bff;
  transition: color 0.2s ease-in-out;
}

.link-list a:hover,
.contact-item:hover {
  color: #0056b3;
}

.contact-item {
  display: flex;
  align-items: center;
}

.contact-item i {
  margin-right: 10px;
}

.contact-text {
  margin: 0;
}